import authSlice from '../reducers/auth'

const { loginSuccess } = authSlice.actions

const login = () => async dispatch => {
  localStorage.setItem('accessDoctorPink', true);
  dispatch(loginSuccess(true));
}

const authActions = {
    login,
}


export default authActions;