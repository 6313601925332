import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    access: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.access = action.payload;
    },
  },
});


export default authSlice