import { useEffect } from 'react';
import './landing.scss';
import { Sakura } from '../../../../component/Saruka';
import Flowers from '../../../../assets/images/Flowers.png';
import Title from '../../../../assets/images/Title.svg';
import Line from '../../../../assets/images/line-with-circle.svg';
import Logo from '../../../../component/Logo';

const LandingPage = ({ exit, setExit }) => {
    useEffect(() => {
            new Sakura('div.landing-page-container', {
                fallSpeed: 3,
                delay: 400
            });
            let sky = document.querySelector('.sky');
            const createDiv = (size) => {
                let circle = document.createElement('div');
                circle.classList.add('circle');
                
                let randRange5 = Math.floor(Math.random() * 5) + 1;
                circle.classList.add(`blink_${randRange5}`);
                
                let widthAndHeight = random(size, 'px');
                circle.style.height = circle.style.width = widthAndHeight;
                
                circle.style.left = random(window.innerWidth, 'px');
                circle.style.top = random(window.innerHeight, 'px');
                
                sky.appendChild(circle);
            }
    
            const paintStars = (stars, size) => {
                while (sky.firstChild) {
                    sky.removeChild(sky.firstChild);
                }
                for (let i = 0; i < stars; i++) {
                    createDiv(size);
                }
            }
    
            const random = (range, unit) => {
                let randNum = Math.floor(Math.random() * range) + 1;
                return `${randNum}${unit}`;
            }
            paintStars(50, 35);
    }, []) 

    const onBegin = () => {
        setExit(prev => !prev);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        new Sakura('div.landing-page-container').stop('graceful');
    }

    return (
        <div className={`landing-page-container ${exit && 'exit'}`}>
            <div className="sky"></div>
            <img className='flowers' src={Flowers} alt='Flowers' draggable="false" />
            <div className='content-container'>
                <img className='title' src={Title} alt='Title' draggable="false" />
                <button onClick={onBegin}>BEGIN</button>
            </div>
            <img className='line' src={Line} alt='Line' draggable="false" />
            <div className='logo'>
                <Logo color={'#fb6595'} width={65} height={65} initial="true" />
            </div>
        </div>
    )

}

export default LandingPage;