import { useEffect, useState } from 'react';
import './login.scss';
import { Sakura } from '../../component/Saruka';
import Logo from '../../component/Logo';
import CircleBG from '../../assets/images/orbit-with-star-and-circle.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../../store/actions';

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    useEffect(() => {
        document.getElementById('password').focus();
    }, [])

    const onBodyClick = () => {
        document.getElementById('password').focus();
    }

    useEffect(() => {
        const petalsOnInput = () => {
            if (password === '07.30') {
                new Sakura('div.login-container', {
                    fallSpeed: 2,
                    delay: 100
                });
                document.getElementById('password').setAttribute('disabled', '');
                setTimeout(() => { 
                    dispatch(actions.auth.login()).then(() => {
                        navigate('/')
                    })
                 }, 20000);
            } else {
                new Sakura('div.login-container').stop('graceful');
            }
        }
        petalsOnInput()
    }, [dispatch, navigate, password])


    useEffect(() => {
        if (password === '07.30') {
            let sky = document.querySelector('.sky');
            const createDiv = (size) => {
                let circle = document.createElement('div');
                circle.classList.add('circle');
                
                let randRange5 = Math.floor(Math.random() * 5) + 1;
                circle.classList.add(`blink_${randRange5}`);
                
                let widthAndHeight = random(size, 'px');
                circle.style.height = circle.style.width = widthAndHeight;
                
                circle.style.left = random(window.innerWidth, 'px');
                circle.style.top = random(window.innerHeight, 'px');
                
                sky.appendChild(circle);
            }
    
            const paintStars = (stars, size) => {
                while (sky.firstChild) {
                    sky.removeChild(sky.firstChild);
                }
                for (let i = 0; i < stars; i++) {
                    createDiv(size);
                }
            }
    
            const random = (range, unit) => {
                let randNum = Math.floor(Math.random() * range) + 1;
                return `${randNum}${unit}`;
            }
            paintStars(35, 28);
        }
    }, [password]) 



    return (
        <div className={`login-container ${password === '07.30' && 'fadeout'}`} onClick={onBodyClick}>
            <div className='background-circle'>
                <img className={`${password === '07.30' && 'active'}`} src={CircleBG} alt='Circle Orbit' draggable="false" />
            </div>
            {
                password === '07.30' &&
                <>
                    <div className="sky"></div>
                    <div className={`logo-container ${password === '07.30' && 'fadein'}`}>
                        <Logo />
                    </div>
                </>
            }
            <div className={`center-container ${password === '07.30' && 'fadeout'}`}>
                <input id='password' type='text' onChange={(e) => setPassword(e.target.value)} maxLength={5}/>
                <div className='password-container'>
                    <p className='gold'>{password}</p>
                </div>
            </div>
        </div>
    )

}

export default Login;