import { Routes, Route } from "react-router-dom";
import Login from "./routes/Login";
import Home from "./routes/Home";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import { useEffect } from "react";
import Entry from "./routes/Entry";
import Sparkle from "./component/Sparkles";

const App = () => {
  const dispatch = useDispatch();
  const { access} = useSelector((state) => state.auth);
  
  useEffect(() => {
    if (localStorage.getItem('accessDoctorPink'))
      dispatch(actions.auth.login())
  }, [dispatch]);

  useEffect(() => {
    Sparkle();
  }, [])
  
  return (
    <Routes>
      {
        access
        ? <>
          <Route path="/" element={<Home />} />
          <Route path="/chapter/:id" element={<Entry />} />

        </> 
        : <Route path="/" element={<Login />} />
      }
    </Routes>
  );
}


export default App;
