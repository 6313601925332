import { useState } from 'react';
import './home.scss';
import Chapters from './modules/Chapters';
import LandingPage from './modules/LandingPage';

const Home = () => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div className="home-container">
            <LandingPage exit={loaded} setExit={setLoaded} />
            <Chapters loaded={loaded} />
        </div>
    )

}

export default Home;