import * as React from "react";
import './logo.scss';

const Logo = (props) => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1241.21 986.94"
    width={1241.2099609375}
    height={986.9400024414062}
    {...props}
  >
    <defs>
      <style>
        {
          ".d{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:60px;}"
        }
      </style>
    </defs>
    <g id="c">
      <path
        className={`d svg-elem-1 ${props.initial && 'initial'}`}
        stroke={props.color || '#fff'}
        d="M246.46,382.78S637.18,45.25,1042.59,122.29c405.4,77.04,60.54,834.65-827.31,834.65"
      />
      <path
        stroke={props.color || '#fff'}
        className={`d svg-elem-2 ${props.initial && 'initial'}`}
        d="M30,492.84s278.83,111.9,647.54-99.06,331.69-662.22-60.54-69.71c-172.43,260.48-199.95,421.91-223.8,513.63"
      />
    </g>
  </svg>
)

export default Logo
