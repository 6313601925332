import { useEffect, useMemo, useState } from 'react';
import './entry.scss';
import Flower from '../../assets/images/flower.png'
import { useNavigate, useParams } from "react-router-dom";
import StartOne from '../../assets/images/StartOne.jpg'
import StartTwo from '../../assets/images/StartTwo.jpg'
import GoalOne from '../../assets/images/GoalOne.jpg'
import GoalTwo from '../../assets/images/GoalTwo.jpg'
import BadOne from '../../assets/images/BadOne.jpg'
import BadTwo from '../../assets/images/BadTwo.jpg'
import GiftOne from '../../assets/images/GiftOne.jpg'
import GiftTwo from '../../assets/images/GiftTwo.jpg'
import HanzHollow from '../../assets/HanzHollow.wav'

const Entry = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [chapter, setChapter] = useState(null);
    const [start, setStart] = useState(false);
    const [audio, setAudio] = useState(null);
    const [fadeTimer, setFadeTimer] = useState(null);

    const ChapterOneLetter = () => {
        return (
            <p>
                Grabe noh, sobrang bilis ng panahon. We’ve been together for 7 years and 6 month, at malapit na sa ating 8th year anniversary. Sabi ni google Bronze year daw pag 8 years na, konting kintab lang gold na din naman iyon. Hanggang ngayon hindi padin ako nag sasawa, andon padin ung kilig at pag mamahal ko simula sa una. Syempre sasabihin mo hindi ako sweet noon sayo, I know naman hindi ko din alam na sweet pala akong tao. Ikaw lang ang nag pa open up sakin ng ganito.
                <br /><br />
                Eto nanaman tayo, Valentine’s day ulit. There’s a lot of things that happened, nakapag sama tayo for couple of months. Naranasan natin ang feeling ng magkasama, naka lived-in. It was the best couple of months and I want to experience it even more. Ganon pala feeling ng kasama mo ung mahal mo araw-araw. Syempre hindi naman always okay tayo, may mga araw na stress ka at burned out. Pero in the end of the day mag katabi tayong natutulog and that comfort always makes my day.
                <br /><br />
                Ang sarap mong alagaan, it was fullfilling. Iba sa feeling ung na aalagaan mo ung mahal mo. Sa loob ng ilang buwan na yon napatunayan ko kung gaano talaga kita ka-mahal. I love you, you made me do the things that I don’t normally do. Napag-linis mo ko ng bahay, napag-laba mo ko ng mga damit, at napag-urong mo ko ng hugasin. You made me a better person. 
                <br /><br />
                I love and miss the days, pero alam ko this is just the start. Patikim palang sa atin yon, and I will do everything to have it again. This will be our start, our year, and everything will fall into place. Lagi mong tatandaan na andito ako para sayo mulang umpisa hanggang dulo. I love you so much baby ko.
            </p>
        )
    };

    const ChapterTwoLetter = () => {
        return (
            <p>
                Kakaiba pag-pasok ng taon sa atin, pero alam ko naman makakayanan din natin to. I want to give you the place that you want. Ung lugar natin na ikaw mismo mag aayos, mag lalagay ng decoration, ung tipong magiging comfortable ka. That’s my goal, to give you the best. Mag sisipag ako maghanap ng work and other clients, gusto ko talaga buuin ang mga plano ko for us. I want to make a home with you.
                <br /><br />
                I know feeling mo walang nangyayari sa goal natin. You are stuck in your studies. Sabi mo nga wala ka pang napapatunayan hanggang ngayon, pero sa paningin ko you are gaining. The things you’ve done these past years are your achievements, kinaya mo sila. Hindi lahat ng achivement kailangan involve ang pera. I remember pa nung nag start ka ng medicine, takot ka kase baka hindi kita maantay. Pero sabi ko nga sayo I can wait basta ma achive mo ung goal mo. I was proud makita ka sa stage when you graduate, I saw my future doctor. 
                <br /><br />
                Sa pag pasok ng taon isa lang ang goal ko makasama ka ng tuluyan. Hindi magiging madali pero alam ko makakaya ko din yon. I want to spend my lifetime with you. Di ko makita ang future na wala ka sa tabi ko. You gave me a goal, you are my motivation. I love you and I will always be with you. I hope that we can be together as soon as possible, I miss you. Miss ko ung kulitan natin tuwing gabi, ung pag kiliti mo sa sarili mo ng walang dahilan, at ung bonding natin. I miss watching late night anime with you, our midnight snacks together, and playing games kahit alam kong ayaw mo ng natatalo. I want to experience it again soon with you.
            </p>
        )
    };

    const ChapterThreeLetter = () => {
        return (
            <p>
                Alam kong alam mo na naman mga bad sides mo, I accept it. I also want you to know na kahit gaano pa kasama ugali mo I will still be here, papagalitan at pipigilan kita. I wont get turn off sa bad sides mo, despite all of it mas lamang padin ang mga good sides mo.
                <br /><br />
                Ang ayoko lang talaga ung sinasaktan mo sarili mo at nag sisira ka ng gamit. Ganon ako before, sinasaktan ko sarili ko nung bata ako. Madalas kong iuntog ulo ko sa pader noon, and I think even now pag di ko na kaya ang stress. Sinusubukan ko den hindi maging mainitin ang ulo dahil mahirap pag pareho tayong ganon.
                <br /><br />
                Sana wag mo na sasaktan sarili mo, I’m here to comfort you. I love you, so please I don’t want to see you getting hurt. I know how stressful you are these days, and I want to be someone na mag bibigay sayo ng relief. I want to be the person na mag papagaan ng pakiramdam mo at tutulong sayo.
                <br /><br />
                I’m your friend, best friend, boyfriend, and soon your best partner in life, andito ko para maging comfort mo. I’m here to listen, to talk with, and to be someone you can release your stress with. Wag ka mahihiya sa akin, dahil it’s my job to do so. I will always be here for you, ako ang number 1 mong kakampi. I love you, and I’m here for better or worse.
            </p>
        )
    };

    const ChapterFourLetter = () => {
        return (
            <p>
                I wanted to give you something na magagamit mo. This will be your portfolio, brand identity, and best bookmark of yourself to the world. To follow ung extravagant na flowers. I wanted to give you one. Medyo masakit nga sa puso nung nakita ko na binilhan mo sarili mo ng bulaklak. Dapat ako yon e, I’m suppose to be your flower guy.
                <br /><br />
                I know na madami kang gifts na gusto matanggap, nakalista naman sa akin mga yon. Ang dami ko nading naipon na promises sayo na dapat bibilhin pero hindi natutuloy. Maibibigay ko din sayo lahat nang yan, please be patient with me. Magiging successful then tayo, it will be the best gift for us.
                <br /><br />
                Sabi ko nga sayo, every year on February 14 I will surprise you. Hiding hindi ako mag sasawa bigyan ka ng supresa. This website is for you, you are the Pink Doctor. Sa ngayon simple palang to at para lang sa letter ko, but once you become a certified doctor gagawin nating pang professional tong website. This is the best gift I could think of as a developer, at alam kong magagamit mo to in the near future. 
                <br /><br />
                I’m sorry kung this is all I can give you right now. Madami akong plans and ideas for a gift but I choose this one. It also represents my improvements in my job. Naalala mo pa ba ung 1st website na ginawa ko sayo noon? That was 5 years ago and wala din akong work noon. This is how I improved, and will be improving until I achieve my goals for our future.
                <br /><br />
                I hope that this gift will give you the smiles. Sana matuwa ka sa simpleng messages ko para sayo. I promise to give you more gifts and surprises. Happy Valentine's baby ko, I love you so much, and I always do. You are the best gift that came to my life.

            </p>
        )
    };

    const chapterData = useMemo(() => [
        {
            id: 1,
            title: 'The Start',
            number: 'I',
            picture_one: StartOne,
            picture_one_message: 'I love how we look good together.',
            picture_two: StartTwo,
            picture_two_message: 'You made me complete. I love you!',
            chapter_quote: 'This will be our start, our year, and everything will fall into place.',
            letter: <ChapterOneLetter />
        },
        {
            id: 2,
            title: 'The Goals',
            number: 'II',
            picture_one: GoalOne,
            picture_one_message: 'I want to go back here with you.',
            picture_two: GoalTwo,
            picture_two_message: 'My beautiful baby and her boy helper.',
            chapter_quote: 'You gave me a goal, you are my motivation.',
            letter: <ChapterTwoLetter />
        },
        {
            id: 3,
            title: 'The Bads',
            number: 'III',
            picture_one: BadOne,
            picture_one_message: 'You stole my heart, I stole your picture.',
            picture_two: BadTwo,
            picture_two_message: 'Natural beauty, no make up no filter.',
            chapter_quote: 'I love you, and I’m here for better or worse.',
            letter: <ChapterThreeLetter />
        },
        {
            id: 4,
            title: 'The Gift',
            number: 'IV',
            picture_one: GiftOne,
            picture_one_message: 'I love how little things makes you happy.',
            picture_two: GiftTwo,
            picture_two_message: 'I love you so much, and forever will.',
            chapter_quote: 'You are the best gift that came to my life.',
            letter: <ChapterFourLetter />
        },
    ], [])
    useEffect(() => {
        let sky = document.querySelector('.sky');
        const createDiv = (size) => {
            let circle = document.createElement('div');
            circle.classList.add('circle');

            let randRange5 = Math.floor(Math.random() * 5) + 1;
            circle.classList.add(`blink_${randRange5}`);

            let widthAndHeight = random(size, 'px');
            circle.style.height = circle.style.width = widthAndHeight;

            circle.style.left = random(window.innerWidth, 'px');
            circle.style.top = random(window.innerHeight, 'px');

            sky.appendChild(circle);
        }

        const paintStars = (stars, size) => {
            while (sky.firstChild) {
                sky.removeChild(sky.firstChild);
            }
            for (let i = 0; i < stars; i++) {
                createDiv(size);
            }
        }

        const random = (range, unit) => {
            let randNum = Math.floor(Math.random() * range) + 1;
            return `${randNum}${unit}`;
        }
        paintStars(50, 35);
        setAudio(new Audio(HanzHollow));
    }, [])

    useEffect(() => {
        const findChapter = chapterData.filter(item => item.id === parseInt(params.id))
        setChapter(findChapter[0]);
    }, [chapterData, params.id])

    const goBack = () => {
        navigate(`/chapter/${parseInt(params.id) - 1}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const goNext = () => {
        navigate(`/chapter/${parseInt(params.id) + 1}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const onBack = async () => {
        if (audio) {
            await audioFadeOut();
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const onStart = () => {
        if (audio) {
            audioPlay();
            setStart(true);
        }
    }

    const audioPlay = () => {
        clearTimeout(fadeTimer);
        audio.volume = 0;
        audio.currentTime = 0;
        audio.loop = true;
        audio.play();
        audioFadeIn();
    };

    const audioFadeIn = () => {
        clearTimeout(fadeTimer);
        if (audio) {
            if (audio.volume < 9.995) {

                audio.volume = Math.min(1, audio.volume + 0.005);
                setFadeTimer(setTimeout(audioFadeIn, 10));
            } else {
                audio.volume = 1;
            }
        }
    };

    const audioFadeOut = () => {
        clearTimeout(fadeTimer);
        if (audio) {
            if (audio.volume > 0.005) {
                audio.volume = Math.min(1, audio.volume - 0.005);
                setFadeTimer(setTimeout(audioFadeOut, 5))
            } else {
                audio.volume = 0;
                audio.pause();
                audio.currentTime = 0;
            }
        }
    };

    return (
        <>
            <div className={`start-chapter ${start && 'fadeout'}`}>
                <div className="sky"></div>
                <button onClick={onStart}>{chapter?.title}</button>
            </div>
            {
                start &&
                <div className='entry-container'>
                    <div className='entry-info'>
                        <p className='chapter-number'>CHAPTER {chapter?.number}</p>
                        <p className='chapter-title'>{chapter?.title}</p>
                        <div className='photos'>
                            <div>
                                <img className='photo' src={chapter?.picture_one} alt='' draggable="false" />
                                <p className='photo-text'>
                                    {chapter?.picture_one_message}
                                </p>
                            </div>
                            <div>
                                <img className='photo' src={chapter?.picture_two} alt='' draggable="false" />
                                <span className='photo-text'>
                                    {chapter?.picture_two_message}
                                </span>
                            </div>
                        </div>
                        <p className='message'>{`"${chapter?.chapter_quote}"`}</p>
                        <img className='flower' src={Flower} alt='' draggable="false" />
                    </div>
                    <div className='entry-letter'>
                        <div>
                            <div className='line'></div>
                            <div className='letter'>
                                {chapter?.letter}
                            </div>
                        </div>
                        <div className='buttons'>
                            {
                                parseInt(params.id) > 1
                                    ? <button onClick={goBack}>CHAPTER {parseInt(params.id) === 4 ? 'III' : parseInt(params.id) === 3 ? "II" : "I"}</button>
                                    : <button onClick={onBack}>GO BACK</button>
                            }

                            {
                                parseInt(params.id) < 4
                                    ? <button onClick={goNext}>CHAPTER {parseInt(params.id) === 1 ? 'II' : parseInt(params.id) === 2 ? "III" : "IV"}</button>
                                    :
                                    <div className='hearts-svg' onClick={onBack}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="auto"
                                            height="auto"
                                            viewBox="0 0 323.101 207.44"
                                        >
                                            <defs>
                                            </defs>
                                            <g transform="translate(-1521.626 -816.261)">
                                                <text className="hearts-day" transform="translate(1659.5 898.123) rotate(-19)">
                                                    <tspan x={-80.408} y={0}>
                                                        {"Happy"}
                                                    </tspan>
                                                </text>
                                                <text className="hearts-day" transform="translate(1679.5 956.054) rotate(-19)">
                                                    <tspan x={-140.828} y={0}>
                                                        {"HeartsDay!"}
                                                    </tspan>
                                                </text>
                                                <path
                                                    className="heart-shape"
                                                    d="M6578.094,9281.916s5.3-39.626-20.555-33.692,13.986,75.438,22.25,82.43c19.495-15.045,68.655-57,50.22-77.132-7.577-8.273-19.27-5.487-30.808,1.387-16.119,9.6-31.738,27.487-33.61,32.729"
                                                    transform="translate(-3262.81 -9232.023) rotate(9)"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default Entry;