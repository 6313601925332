import './chapters.scss';
import CircleBG from '../../../../assets/images/orbit-with-star-and-circle.svg';
import THEBAD from '../../../../assets/images/THEBAD.png';
import THESTART from '../../../../assets/images/THESTART.png';
import THEGIFT from '../../../../assets/images/THEGIFT.png';
import THEGOAL from '../../../../assets/images/THEGOAL.png';
import { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';

const Chapters = ({ loaded }) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const chapters = [
        {
            number: 'I',
            background: THESTART,
            title: 'Start',
        },
        {
            number: 'II',
            background: THEGOAL,
            title: 'Goals',
        },
        {
            number: 'III',
            background: THEBAD,
            title: 'Bads',
        },
        {
            number: 'IV',
            background: THEGIFT,
            title: 'Gift',
        },
    ];

    useEffect(() => {
        if (loaded) {
            setTimeout(() => { 
                setShow(prev => !prev);
             }, 2000);
        }

    }, [loaded]);

    const goChapter = (index) => {
        navigate(`/chapter/${index + 1}`);
    }

    return (
        <div className="chapters-container">
            <div className='background-circle'>
                <img className={` ${show && 'active'}`} src={CircleBG} alt='Circle Orbit' draggable="false" />
            </div>

            <div className={`chatpter-content-container ${show && 'loaded'}`}>
                <p className='prefix'>THE</p>
                <p className='title'>CHAPTERS</p>
                <div className='chapters'>
                    {
                        chapters.map((item, index) => {
                            return (
                                <div 
                                    className='chapter' 
                                    key={index} 
                                    style={{ backgroundImage: `url(${item.background})` }}
                                    onClick={() => goChapter(index)}
                                >
                                    <div className='number'>
                                        <p>{item.number}</p>
                                    </div>
                                    <div className='overlay'></div>
                                    <div className='chap-title'>
                                        <p>The</p>
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default Chapters;